/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                this.srcllFooter();
            },
            srcllFooter: function () {
                $('.menu-rolunk').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#rolunk").offset().top},
                        'slow');
                });
                $('.menu-client-testimonials').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#client-testimonials").offset().top},
                        'slow');
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                this.scroll();
            },
            scroll: function () {
                $('.menu-orarend').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#orarend").offset().top
                        },
                        'slow');
                });
                $('.menu-helyszin').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#helyszin-parkolas").offset().top
                        },
                        'slow');
                });
                $('.menu-bejelentkezes').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#bejelentkezes").offset().top
                        },
                        'slow');
                });
                $('.menu-oktatok').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#oktatok").offset().top
                        },
                        'slow');
                });
                $('.menu-arak').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#arak").offset().top
                        },
                        'slow');
                });
                $('.menu-schedule').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#schedule").offset().top
                        },
                        'slow');
                });
                $('.menu-location').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#location-parking").offset().top
                        },
                        'slow');
                });
                $('.menu-sign-up').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#sign-up-for-a-class").offset().top
                        },
                        'slow');
                });
                $('.menu-instructors').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#instructors").offset().top
                        },
                        'slow');
                });
                $('.menu-prices').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                            scrollTop: $("#prices").offset().top
                        },
                        'slow');
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
